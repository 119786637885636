/* eslint-disable array-callback-return */
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import logo from "Default.png";
import { authenticationService } from "services/Authentication.service.js";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();

    this.state = { currentUser: null };
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    authenticationService.currentUser.subscribe((x) => {
      this.setState({ currentUser: x });
    });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          menuIsOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          menuIsOpen: !this.state[targetName].menuIsOpen,
        },
      });
    }
  };

  isMenuOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].menuIsOpen : false;
  };

  render() {
    const { currentUser } = this.state;

    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          {/* <a href="/admin/dashboard" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a> */}
          <a
            href="/admin/dashboard"
            className="simple-text logo-normal"
            style={{ textAlign: "center" }}
          >
            Lumos Onboarding
          </a>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.children && prop.children.length > 0) {
                return (
                  currentUser !== null && (
                    <li key={key}>
                      <NavLink
                        to="#"
                        className="nav-link"
                        onClick={() => this.toggle(prop.name)}
                      >
                        <i className={prop.icon}></i>
                        <p>
                          {prop.name}
                          <b
                            className={
                              "caret " +
                              (this.isMenuOpen(prop.name) ? " upMenu" : "")
                            }
                          ></b>
                        </p>
                      </NavLink>

                      <Collapse isOpen={this.isMenuOpen(prop.name)}>
                        <ul className="nav">
                          {prop.children.map((child, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  this.activeRoute(child.path) +
                                  (child.pro ? " active-pro" : "")
                                }
                              >
                                <NavLink
                                  to={child.layout + child.path}
                                  className="nav-link"
                                  activeClassName="active"
                                >
                                  <i className={child.icon} />
                                  <p>{child.name}</p>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  )
                );
              } else {
                return (
                  currentUser !== null && (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </NavLink>
                    </li>
                  )
                );
              }
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
