import React from "react";
import * as dateFns from "date-fns";
import CalendarCell from "./Calendar.cell";

class CustomCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonth: this.props.currentMonth,
      attendance: this.props.attendance,
      currentSemester: this.props.currentSemester,
    };
  }

  renderHeader() {
    const dateFormat = "MMMM yyyy";

    return (
      <div className="header row flex-middle">
        <div className="col col-center">
          <span>{dateFns.format(this.props.currentMonth, dateFormat)}</span>
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    let currentMonth = nextProps.currentMonth;
    let currentSemester = nextProps.currentSemester;
    let attendance = nextProps.attendance;

    attendance = JSON.parse(JSON.stringify(attendance));

    this.setState({
      currentMonth: currentMonth,
      attendance: attendance,
      currentSemester: currentSemester,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      dateFns.format(this.state.currentMonth, "MMddyyyy") !==
        dateFns.format(nextProps.currentMonth, "MMddyyyy") ||
      this.state.attendance.length !== nextProps.attendance.length ||
      this.state.currentSemester !== nextProps.currentSemester
    );
  }

  renderDays() {
    const dateFormat = "iii";
    const days = [];

    let startDate = dateFns.addDays(
      dateFns.startOfWeek(this.props.currentMonth),
      1
    );

    for (let i = 0; i < 4; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  isHoliday(date) {
    return this.props.tables.holidays.find(
      (x) => x.date === dateFns.format(date, "yyyy-MM-dd") + "T00:00:00"
    );
  }

  renderCells() {
    const monthStart = dateFns.startOfMonth(this.props.currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);

        let attendance1 = this.getAttendance(day, "1");
        let attendance2 = this.getAttendance(day, "2");
        let isHoliday = this.isHoliday(day);
        let leftNote =
          attendance1 !== undefined && attendance1.comment > ""
            ? " divCalendarLeft"
            : "";
        let rightNote =
          attendance2 !== undefined && attendance2.comment > ""
            ? " divCalendarRight"
            : "";

        let disabled =
          !dateFns.isSameMonth(day, monthStart) ||
          day < dateFns.parseISO(this.props.currentSemester.startDate) ||
          day > dateFns.parseISO(this.props.currentSemester.endDate) ||
          dateFns.isWeekend(day) ||
          dateFns.isFriday(day) ||
          isHoliday
            ? " disabled"
            : "";

        if (i !== 0 && i !== 5 && i !== 6) {
          days.push(
            <div
              id="divCalendar"
              className={`col cell ${disabled} ${leftNote} ${rightNote}`}
              key={day}
            >
              <span className="number">{formattedDate}</span>

              {attendance1 !== undefined && (
                <CalendarCell
                  day={day}
                  monthStart={monthStart}
                  classPosition="leftCalendar"
                  block="1"
                  hasDate={this.hasDate.bind(this, day, "1")}
                  attendance={attendance1}
                  onClick={this.props.onClick}
                ></CalendarCell>
              )}

              {attendance2 !== undefined && (
                <CalendarCell
                  day={day}
                  monthStart={monthStart}
                  classPosition="rightCalendar"
                  block="2"
                  hasDate={this.hasDate.bind(this, day, "2")}
                  attendance={attendance2}
                  onClick={this.props.onClick}
                ></CalendarCell>
              )}
            </div>
          );
        }

        day = dateFns.addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );

      days = [];
    }

    return <div className="body">{rows}</div>;
  }

  hasDate(day, block) {
    return this.props.attendance.find(
      (x) =>
        x.classBlock === block && x.date === dateFns.format(day, "MM/dd/yyyy")
    );
  }

  getAttendance(day, block) {
    return this.props.attendance.find(
      (x) =>
        x.classBlock === block && x.date === dateFns.format(day, "MM/dd/yyyy")
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="calendar">
          {this.renderHeader()}
          {this.renderDays()}
          {this.renderCells()}
        </div>
      </React.Fragment>
    );
  }
}

export default CustomCalendar;
