import Dashboard from "views/Dashboard.jsx";
import Attendance from "views/Attendance";
import Messages from "views/Messages";
import Calendar from "views/Calendar";
import TestRetake from "views/TestRetake";
import Tools from "views/Tools";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-bar-32",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/message",
    name: "Message",
    icon: "fas fa-envelope",
    component: Messages,
    layout: "/admin",
  },

  {
    path: "/lumoscalendar",
    name: "Lumos Calendar",
    icon: "fas fa-calendar-alt",
    component: Calendar,
    layout: "/admin",
  },
];
export default routes;
