/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row } from "reactstrap";
import StudentToolLink from "components/StudentToolLink/StudentToolLink";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class Tools extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      Student: {
        Subjects: [],
        Messages: [],
        Invoices: [],
      },
      AttendanceChartList: [],
      Message: {},
      ModalMessageIsOpen: false,
      SelectClassIdForAssignmentModal: "",
      AssignmentModalIsOpen: false,
    };
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <StudentToolLink
              url={"https://outlook.office365.com/mail/inbox"}
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/email-pt5sk8z6pcw4utiq0vpiw4iqx0e101mwb8dbvqkpns.png"
              }
              name={"Access Email"}
            />

            <StudentToolLink
              url={"https://lumosls.edu/login/index.php"}
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/icon_Lumos_LMS-pzshoj47kcuewq2jz3u0r5kogqdah6r0yv635uv3e0.png"
              }
              name={"Lumos LMS"}
            />

            <StudentToolLink
              url={
                "https://outlook.office365.com/owa/calendar/LumosLanguageSchool@lumos.edu/bookings/"
              }
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/booking-pt5sk73ibotk7llgbuw9r4ztq8naknffmz2cx6ni08.png"
              }
              name={"Booking Appointment"}
            />

            <StudentToolLink
              url={
                "https://jobs.gusto.com/boards/lumos-language-school-job-opportunities-ae21f5df-e243-4c55-808d-7df33b8c46e7"
              }
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/jobs-icon-puq7uml2ape335djc5rdcxsoy5s3eoacx75nc5d620.png"
              }
              name={"Jobs"}
            />

            <StudentToolLink
              url={"https://toefl.lumos.edu"}
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/ico_sis-pt5skauv30ypi1fzpwis141o3s4rffuczhoauahxbc.png"
              }
              name={"TOEFL"}
            />

            <StudentToolLink
              url={"https://form.jotform.com/221867618462161"}
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/icon_complains-pt5skbsp9uzztnemkexellt4p604n4y3bmbsbkgj54.png"
              }
              name={"Formal Complaints"}
            />

            <StudentToolLink
              url={"https://media.lumosls.edu"}
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/book_audio-q34pjtdjavso093u7xly4ql777t4dizpxj0ayvs2y0.png"
              }
              name={"Audio Books"}
            />

            <StudentToolLink
              url={"http://lumos.yclas.com/"}
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/marketplace-pxdjsqe3pm66qbychvfc1zzyv919omn5tg1fdie4mg.png"
              }
              name={"Marketplace"}
            />

            <StudentToolLink
              url={"https://lumoslanguageschool.freshdesk.com/support/home"}
              imgUrl={
                "https://lumos.edu/wp-content/uploads/elementor/thumbs/contact-pt5sk81ciiuuj7k36dawbmrabminscj5z3puegm3u0.png"
              }
              name={"Contact Support"}
            />
          </Row>
        </div>
      </>
    );
  }

  async componentDidMount() {}

  componentWillUnmount() {}
}

export default Tools;
