import { authenticationService } from "services/Authentication.service.js";

export function authHeader() {
  const user = authenticationService.currentUserValue;

  let obj = { "Content-Type": "application/json" };

  if (user && user.token) {
    obj.Authorization = "Bearer " + user.token;
  }

  return obj;
}
