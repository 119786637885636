/* eslint-disable array-callback-return */
import React, { Fragment } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Switch } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute.jsx";
import axios from "axios";

var ps;

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      tables: {},
      finished: false,
    };
    this.mainPanel = React.createRef();
  }

  componentDidMount() {
    this.getTables().then(() => {
      this.setState({ finished: true });
    });

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  async getTables() {
    await axios.get("dashboard/tables").then((response) => {
      let mainState = this.state;

      this.setState(mainState);
    });
  }

  render() {
    let privateRoutes = [];

    routes.map((prop, key) => {
      if (prop.children && prop.children.length > 0) {
        for (let index = 0; index < prop.children.length; index++) {
          const child = prop.children[index];
          privateRoutes.push(
            <PrivateRoute
              key={index}
              path={child.layout + child.path}
              component={child.component}
              tables={this.state.tables}
            />
          );
        }
      } else {
        privateRoutes.push(
          <PrivateRoute
            key={key}
            path={prop.layout + prop.path}
            component={prop.component}
            tables={this.state.tables}
          />
        );
      }
    });

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />

        <div className="main-panel" ref={this.mainPanel}>
          {this.state.finished === true && (
            <Fragment>
              <DemoNavbar {...this.props} tables={this.state.tables} />

              <Switch>{privateRoutes}</Switch>

              <Footer fluid />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default AdminLayout;
