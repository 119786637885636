/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "assets/css/Login.css";

import { authenticationService } from "services/Authentication.service.js";
import ForgotPassword from "../components/User/User.forgotpassword";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }

    this.state = {
      i20: "",
      password: "",
      submitted: false,
      loading: false,
      error: "",
      isForgotPasswordModalOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, error: "" });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { i20, password } = this.state;

    // stop here if form is invalid
    if (!(i20 && password)) {
      return;
    }

    this.setState({ loading: true });
    authenticationService.login(i20, password).then(
      (user) => {
        const { from } = this.props.location.state || {
          from: { pathname: "/" },
        };

        this.props.history.push(from);
      },
      (error) => {
        // var message = ErrorMessage(error);
        let message = "i-20 or password is invalid";
        this.setState({ error: message, loading: false });

        document.getElementById("inputEmail").focus();
      }
    );
  }

  validateForm() {
    return this.state.i20.length > 0 && this.state.password.length > 0;
  }

  toggleisForgotPasswordModalOpen = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.isForgotPasswordModalOpen =
        !mainState.isForgotPasswordModalOpen;
      this.setState(mainState, resolve(true));
    });
  };

  render() {
    const { i20, password, loading, error } = this.state;

    return (
      <div className="centerLogin text-center">
        <div className="card">
          <form className="form-signin" onSubmit={this.handleSubmit}>
            <img
              className="mb-4"
              src="https://www.lumos.edu/wp-content/uploads/2017/07/fox-2.png"
              alt=""
              width="72"
              //height="86"
            />
            <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
            {/* <label for="inputEmail" class="sr-only">
              Email address
            </label> */}
            <input
              type="text"
              id="inputEmail"
              className={`form-control`}
              placeholder="I-20"
              value={i20}
              onChange={this.handleChange}
              name="i20"
              required
            />
            {/* <label for="inputPassword" class="sr-only">
              Password
            </label> */}
            <input
              type="password"
              id="inputPassword"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={this.handleChange}
              name="password"
              required
            />
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              disabled={loading || !this.validateForm()}
            >
              Sign in
            </button>

            <a onClick={() => this.toggleisForgotPasswordModalOpen()} href="#">
              Forgot your password?
            </a>

            <p className="mt-5 mb-3 text-muted">
              &copy; {1900 + new Date().getYear()} Lumos Language School
            </p>
            {error && <div className={"loginAlert"}>{error}</div>}
          </form>
        </div>
        {/* <ForgotPassword
          isOpen={this.state.isForgotPasswordModalOpen}
          toggle={this.toggleisForgotPasswordModalOpen.bind(this)}
        /> */}
      </div>
    );
  }
}

export default LoginPage;
