/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { Row } from "reactstrap";
import * as dateFns from "date-fns";

class CalendarCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      day: this.props.day,
      monthStart: this.props.monthStart,
      classPosition: this.props.classPosition,
      block: this.props.block,

      status: this.props.attendance.status,
      className: this.props.attendance.className,
      recordedBy: this.props.attendance.recordedBy,
      createdDate: this.props.attendance.createdDate,
      comment: this.props.attendance.comment,

      tooltipOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    let day = nextProps.day;
    let monthStart = nextProps.monthStart;
    let classPosition = nextProps.classPosition;
    let block = nextProps.block;

    let status = nextProps.attendance.status;
    let className = nextProps.attendance.className;
    let recordedBy = nextProps.attendance.recordedBy;
    let createdDate = nextProps.attendance.createdDate;
    let comment = nextProps.attendance.comment;

    this.setState({
      day: day,
      monthStart: monthStart,
      classPosition: classPosition,
      block: block,
      status: status,
      className: className,
      recordedBy: recordedBy,
      createdDate: createdDate,
      comment: comment,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      dateFns.format(this.state.day, "MMddyyyy") !==
        dateFns.format(nextProps.day, "MMddyyyy") ||
      dateFns.format(this.state.monthStart, "MMddyyyy") !==
        dateFns.format(nextProps.monthStart, "MMddyyyy") ||
      this.state.classPosition !== nextProps.classPosition ||
      this.state.block !== nextProps.block ||
      this.state.status !== nextProps.attendance.status ||
      this.state.className !== nextProps.attendance.className ||
      this.state.recordedBy !== nextProps.attendance.recordedBy ||
      this.state.createdDate !== nextProps.attendance.createdDate ||
      this.state.comment !== nextProps.attendance.comment ||
      this.state.tooltipOpen !== nextState.tooltipOpen
    );
  }

  render() {
    let dateForTooltip = dateFns.format(this.props.day, "MMddyyyy");

    return (
      <React.Fragment>
        <Row form className={`marks ${this.props.classPosition}`}>
          {this.props.hasDate &&
            dateFns.isSameMonth(this.props.day, this.props.monthStart) && (
              <div>
                <i
                  id={"id_" + dateForTooltip + this.props.block}
                  className={`${this.renderSwitch(
                    this.props.attendance.status
                  )}`}
                  title={this.props.attendance.className}
                />
              </div>
            )}
        </Row>
      </React.Fragment>
    );
  }

  toggleToolTip = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  renderSwitch(param) {
    switch (param) {
      case "P":
        return "nc-icon nc-check-2";
      case "T":
        return "fas fa-exclamation";
      case "A":
        return "nc-icon nc-simple-remove";
      case "NT":
        return "nc-icon nc-simple-delete";
      case "E":
        return "fab fa-etsy";
      default:
        return "nc-icon nc-simple-delete";
    }
  }
}

export default CalendarCell;
