/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Button,
  Collapse,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";
import myData from "../Countries.json";
import { FormatMonthYearDateList, editMode } from "helpers/Utils.js";
import { SavePdf } from "components/Report/GeneratePdf.js";
import * as dateFns from "date-fns";
import { Doughnut, Bar } from "react-chartjs-2";
import { colorArray } from "colors.js";
import ModalMessage from "components/Message/Message.Component";
import AssignmentView from "components/Assignment/Assignment.component.view";
import confirm from "reactstrap-confirm";
import { ReactMic, ReactMicStopEvent } from "react-mic";

const campusColor = ["#6ad0b3", "#fd7c6e", "#fbc657"];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      InitialMessage: false,
      MicrophoneHasBeenTested: false,
      IsRecording: false,
      RecordedBlob: null,
      SecondMessage: false,
      ThirdMessage: false,
      RemainingTime: 45 * 60,
      Questions: [],
      SubmittingAnswers: false,
    };
    this.timerInterval = null;
  }

  _refreshDashboard = () => {
    return new Promise((resolve, reject) => {
      axios.get("questions/get-all").then((response) => {
        let mainState = this.state;

        mainState.Questions = [];

        response.data.data.map((question, i) => {
          let questionOptionList = [];

          question.questionOptionList.map((questionOption, j) => {
            questionOption &&
              questionOptionList.push({
                id: questionOption.id,
                name: questionOption.name,
              });
          });

          mainState.Questions.push({
            id: question.id,
            name: question.name,
            testLetter: question.testLetter,
            questionTypeShortName: question.questionType.shortName,
            questionOptionList: questionOptionList,
            questionOptionIdAnswered: null,
            index: i,
            recordedBlob: null,
            answered: false,
            columnSize: question.columnSize,
          });
        });

        this.setState(mainState, resolve(true));
      });
    });
  };

  async componentDidMount() {
    this._refreshDashboard();
  }

  componentWillUnmount() {}

  setReadState = (name) => {
    this.setState({ [name]: true });
  };

  onStop = (recordedBlob: ReactMicStopEvent) => {
    this.setState({ IsRecording: false, RecordedBlob: recordedBlob.blob });
  };

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  startTimer = () => {
    this.timerInterval = setInterval(() => {
      this.setState((prevState) => ({
        RemainingTime: prevState.RemainingTime - 1,
      }));
    }, 1000);
  };

  startTest = () => {
    this.setReadState("ThirdMessage");
    this.startTimer();
  };

  setquestionOptionId = (questionId, questionOptionId) => {
    let modalState = this.state;

    const index = modalState.Questions.findIndex((x) => x.id === questionId);
    const questions = [...modalState.Questions];
    const question = questions[index];
    question.questionOptionIdAnswered = questionOptionId;

    this.setState(modalState);
  };

  setQuestionAnswered = (questionId) => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let mainState = Object.assign({}, prevState);

        const index = mainState.Questions.findIndex((x) => x.id === questionId);
        const questions = [...mainState.Questions];
        const question = questions[index];
        question.answered = true;

        return { mainState };
      }, resolve(true));
    });
  };

  isSubmitting(bool) {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let mainState = Object.assign({}, prevState);

        mainState.SubmittingAnswers = bool;

        return { mainState };
      }, resolve(true));
    });
  }

  finishSubmit(questionOptionId) {
    const self = this;

    if (this.state.SubmittingAnswers === false) {
      this.setQuestionAnswered(questionOptionId).then(() => {
        this.isSubmitting(true).then(async () => {
          let questionOptionList = [];
          this.state.Questions.map((question, index) => {
            questionOptionList.push({
              Id: question.questionOptionIdAnswered,
            });
          });

          axios
            .post("questions/add-question-option-list", questionOptionList)
            .then((response) => {
              self.isSubmitting(false).then(() => {
                if (response.data.success === true) {
                  alert("Success");
                } else {
                  alert("Error");
                }
              });
            })
            .catch(function (error) {
              self.isSubmitting(false).then(() => {
                alert("Error 2");
              });
            });
        });
      });
    }
  }

  render() {
    const { RemainingTime } = this.state;

    let answeredQuestions = this.state.Questions.filter(
      (x) => x.answered == true
    ).map((question, i) => {
      return (
        <Col
          lg={question.columnSize}
          md={question.columnSize}
          sm={question.columnSize}
        >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="12">
                  <div className="numbers">
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <p
                            className="card-category"
                            style={{ textAlign: "justify" }}
                          >
                            {question.questionTypeShortName == "FI"
                              ? question.name
                              : "Done."}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </Col>
      );
    });

    let sortedQuestions = [...this.state.Questions].sort(
      (a, b) => a.index - b.index
    );

    const nextQuestion = sortedQuestions.find(
      (question) => question.answered == false
    );

    let remainingQuestions = sortedQuestions.filter(
      (x) => x.answered == false
    ).length;

    let questions = this.state.Questions.filter(
      (x) => x.answered == false || x.answered == true
    ).map((question, i) => {
      return (
        <Col
          lg={question.columnSize}
          md={question.columnSize}
          sm={question.columnSize}
          style={{
            display:
              question.answered == true || question.id == nextQuestion.id
                ? ""
                : "none",
          }}
        >
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="12">
                  <div className="numbers">
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <p
                            className="card-category"
                            style={{ textAlign: "justify" }}
                            id={"id_" + question.id}
                          >
                            {question.answered &&
                            question.questionTypeShortName != "FI"
                              ? "Done"
                              : question.name}
                          </p>

                          {question.questionTypeShortName == "LI" && (
                            <audio
                              controls={true}
                              autoPlay={false}
                              loop={false}
                              muted={false}
                              preload="metadata"
                              controlsList="nodownload"
                              id={"id_" + question.id}
                              style={{
                                marginTop: "1rem",
                                display:
                                  question.answered == true ||
                                  question.id != nextQuestion.id
                                    ? "none"
                                    : "",
                              }}
                            >
                              <source
                                src={
                                  "https://hubstorage.lumos.edu/mp3/" +
                                  question.id +
                                  ".mp3"
                                }
                                type="audio/mp3"
                              />
                              Seu navegador não suporta o elemento de áudio.
                            </audio>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    {question.questionOptionList.length > 0 &&
                      question.questionOptionList.map((questionOption, j) => (
                        <div
                          className="form-check form-check-custom form-check-solid"
                          key={j}
                          onClick={this.setquestionOptionId.bind(
                            this,
                            question.id,
                            questionOption.id
                          )}
                          style={{
                            display:
                              question.answered == true ||
                              question.id != nextQuestion.id
                                ? "none"
                                : "",
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            value={"v_" + questionOption.id}
                            id={"id_" + questionOption.id}
                            name={"id_" + question.id}
                          />
                          <label
                            style={{ fontSize: "16px" }}
                            className="form-check-label"
                            htmlFor={"id_" + questionOption.id}
                          >
                            {questionOption.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <Button
                color="success"
                size="sm"
                className="ml-2"
                style={{
                  float: "right",
                  display:
                    question.answered == true || question.id != nextQuestion.id
                      ? "none"
                      : "",
                }}
                onClick={
                  remainingQuestions == 1
                    ? this.finishSubmit.bind(this, question.id)
                    : this.setQuestionAnswered.bind(this, question.id)
                }
                disabled={
                  (question.questionTypeShortName == "LI" ||
                    question.questionTypeShortName == "FB" ||
                    question.questionTypeShortName == "RE" ||
                    question.questionTypeShortName == "PR") &&
                  question.questionOptionIdAnswered == null
                }
              >
                {remainingQuestions == 1 ? "Submit and finish" : "Next"}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      );
    });

    // let nextQuestionObj = null;

    // if (nextQuestion) {
    //   console.log(nextQuestion.id);

    //   nextQuestionObj = (
    //     <Col
    //       lg={nextQuestion.columnSize}
    //       md={nextQuestion.columnSize}
    //       sm={nextQuestion.columnSize}
    //     >
    //       <Card className="card-stats">
    //         <CardBody>
    //           <Row>
    //             <Col md="12">
    //               <div className="numbers">
    //                 <Row form>
    //                   <Col md={12}>
    //                     <FormGroup>
    //                       <p
    //                         className="card-category"
    //                         style={{ textAlign: "justify" }}
    //                         id={"id_" + nextQuestion.id}
    //                       >
    //                         {nextQuestion.name}
    //                       </p>

    //                       {nextQuestion.questionTypeShortName == "LI" && (
    //                         <audio
    //                           controls={true}
    //                           autoPlay={false}
    //                           loop={false}
    //                           muted={false}
    //                           preload="metadata"
    //                           controlsList="nodownload"
    //                           id={"id_" + nextQuestion.id}
    //                         >
    //                           <source
    //                             src={
    //                               "https://hubstorage.lumos.edu/mp3/" +
    //                               nextQuestion.id +
    //                               ".mp3"
    //                             }
    //                             type="audio/mp3"
    //                           />
    //                           Seu navegador não suporta o elemento de áudio.
    //                         </audio>
    //                       )}
    //                     </FormGroup>
    //                   </Col>
    //                 </Row>

    //                 {nextQuestion.questionOptionList.length > 0 &&
    //                   nextQuestion.questionOptionList.map(
    //                     (questionOption, j) => (
    //                       <div
    //                         className="form-check form-check-custom form-check-solid"
    //                         key={j}
    //                         onClick={this.setquestionOptionId.bind(
    //                           this,
    //                           nextQuestion.id,
    //                           questionOption.id
    //                         )}
    //                       >
    //                         <input
    //                           className="form-check-input"
    //                           type="radio"
    //                           value={"v_" + questionOption.id}
    //                           id={"id_" + questionOption.id}
    //                           name={"id_" + nextQuestion.id}
    //                         />
    //                         <label
    //                           style={{ fontSize: "16px" }}
    //                           className="form-check-label"
    //                           htmlFor={"id_" + questionOption.id}
    //                         >
    //                           {questionOption.name}
    //                         </label>
    //                       </div>
    //                     )
    //                   )}
    //               </div>
    //             </Col>
    //           </Row>
    //         </CardBody>
    //         <CardFooter>
    //           <hr />
    //           <Button
    //             color="success"
    //             size="sm"
    //             className="ml-2"
    //             style={{
    //               float: "right",
    //             }}
    //             onClick={
    //               remainingQuestions == 1
    //                 ? this.finishSubmit.bind(this, nextQuestion.id)
    //                 : this.setQuestionAnswered.bind(this, nextQuestion.id)
    //             }
    //           >
    //             {remainingQuestions == 1 ? "Submit and finish" : "Next"}
    //           </Button>
    //         </CardFooter>
    //       </Card>
    //     </Col>
    //   );
    // }

    return (
      <>
        <div className="content">
          {this.state.ThirdMessage == true && (
            <div>
              <p
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                  width: "100%",
                  textAlign: "center",
                  padding: "10px",
                  backgroundColor: RemainingTime <= 200 ? "red" : "green",
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              >
                {RemainingTime <= 0
                  ? "O tempo expirou!"
                  : `Remaining time: ${this.formatTime(RemainingTime)}`}
              </p>
            </div>
          )}
          <Row>
            <Col
              lg="12"
              md="12"
              sm="12"
              // style={{ opacity: this.state.InitialMessage ? "0.3" : "1" }}
            >
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="12">
                      <div className="numbers">
                        <Row form>
                          <Col md={12}>
                            <FormGroup>
                              <p
                                className="card-category"
                                style={{ textAlign: "justify" }}
                              >
                                {this.state.InitialMessage == true
                                  ? "Step 1 done."
                                  : "Before beginning the test, please follow these steps to ensure your microphone is set up properly on your mobile device: 1. Check your microphone: Ensure that your mobile device's microphone is working correctly and is not muted. 2. Browser authorization: When you start the test, your browser may request permission to access your microphone. Look for a pop-up notification and tap 'Allow' to grant the necessary permissions. 3. Confirm access: After granting permission, confirm that your browser has access to the microphone by checking the settings. 4. Proceed with the test: Once authorization is confirmed, you can proceed with the test. Thank you for your attention!"}
                              </p>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  {this.state.InitialMessage == false && (
                    <React.Fragment>
                      <hr />
                      <Button
                        color="success"
                        size="sm"
                        className="ml-2"
                        style={{
                          float: "right",
                        }}
                        onClick={this.setReadState.bind(this, "InitialMessage")}
                      >
                        Next
                      </Button>
                    </React.Fragment>
                  )}
                </CardFooter>
              </Card>
            </Col>

            {this.state.InitialMessage == true && (
              <Col lg="6" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <div className="numbers">
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <p
                                  className="card-category"
                                  style={{ textAlign: "justify" }}
                                >
                                  {this.state.SecondMessage == true
                                    ? "Step 2 done."
                                    : "Click the 'Start Recording' button and then click Play to check if your microphone is working."}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>

                          {this.state.SecondMessage == false && (
                            <React.Fragment>
                              <Row form>
                                <Col md={12}>
                                  <FormGroup
                                    style={{
                                      textAlign: "center",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <Button
                                      color={
                                        this.state.IsRecording
                                          ? "danger"
                                          : "success"
                                      }
                                      size="sm"
                                      className={
                                        this.state.IsRecording
                                          ? "ml-2 pulse"
                                          : "ml-2"
                                      }
                                      onClick={() =>
                                        this.setState({
                                          IsRecording: !this.state.IsRecording,
                                        })
                                      }
                                      disabled={this.state.SecondMessage}
                                    >
                                      {this.state.IsRecording == true
                                        ? "Stop recording"
                                        : "Start recording"}
                                    </Button>
                                  </FormGroup>
                                </Col>
                              </Row>

                              {this.state.RecordedBlob &&
                                this.state.SecondMessage == false && (
                                  <Row form>
                                    <Col md={12}>
                                      <FormGroup
                                        style={{ textAlign: "center" }}
                                      >
                                        <audio controls>
                                          <source
                                            src={URL.createObjectURL(
                                              this.state.RecordedBlob
                                            )}
                                          />
                                        </audio>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                )}

                              <Row
                                form
                                style={{
                                  display: "none",
                                }}
                              >
                                <Col md={12}>
                                  <FormGroup>
                                    <ReactMic
                                      record={this.state.IsRecording}
                                      onStop={this.onStop.bind(this)}
                                      className="sound-wave"
                                      mimeType="audio/wav"
                                      visualSetting=""
                                      style={{ display: "hidden" }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    {this.state.SecondMessage == false && (
                      <React.Fragment>
                        <hr />
                        <Button
                          color="success"
                          size="sm"
                          className="ml-2"
                          style={{
                            float: "right",
                            display:
                              this.state.RecordedBlob != null
                                ? "block"
                                : "none",
                          }}
                          onClick={this.setReadState.bind(
                            this,
                            "SecondMessage"
                          )}
                          disabled={this.state.SecondMessage}
                        >
                          Next
                        </Button>
                      </React.Fragment>
                    )}
                  </CardFooter>
                </Card>
              </Col>
            )}

            {this.state.InitialMessage == true &&
              this.state.RecordedBlob != null &&
              this.state.SecondMessage == true && (
                <Col
                  lg="6"
                  md="6"
                  sm="6"
                  // style={{ opacity: this.state.ThirdMessage ? "0.3" : "1" }}
                >
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <div className="numbers">
                            <Row form>
                              <Col md={12}>
                                <FormGroup>
                                  <p
                                    className="card-category"
                                    style={{ textAlign: "justify" }}
                                  >
                                    {this.state.ThirdMessage == true
                                      ? "Step 3 done."
                                      : "We are ready. From now on, your test will start and last 45 minutes."}
                                  </p>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      {this.state.ThirdMessage == false && (
                        <React.Fragment>
                          <hr />
                          <Button
                            color="success"
                            size="sm"
                            className="ml-2"
                            style={{
                              float: "right",
                            }}
                            onClick={this.startTest.bind(this)}
                            disabled={this.state.ThirdMessage}
                          >
                            Start
                          </Button>
                        </React.Fragment>
                      )}
                    </CardFooter>
                  </Card>
                </Col>
              )}

            {/* {this.state.ThirdMessage == true && answeredQuestions} */}
            {/* {this.state.ThirdMessage == true && nextQuestionObj} */}
            {this.state.ThirdMessage == true && questions}
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
